// @ts-nocheck
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { getContactDetails } from "../../../api/contact";
import EditContactForm from "../../../components/form/EditContactForm";
import ContentLoader from "../../../components/ui/ContentLoader";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export default function EditEmailContact() {
  const params = useParams();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  // functions
  const fetchUserData = () => {
    setLoading(true);
    getContactDetails(params.id)
      .then((res) => {
        setUser(res.data.payload);
        setLoading(false);
      })
      .catch((error) => console.log("Error: ", error));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      <Helmet title="Edit Mailing Contact" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Mailing Contact
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Link component={NavLink} to="/notification/all">
              Mailing Contact List
            </Link>

            <Typography>Edit Mailing Contact</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={3} />
      <Grid container spacing={6} justifyContent={"center"} alignItems="center">
        <Grid item xs={10} md={7}>
          <Card p={8}>
            {loading ? <ContentLoader /> : <EditContactForm user={user} />}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
