// @ts-nocheck
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Select,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../ui/ErrorAlert";
import ImageFieldError from "../ui/ImageFieldError";
import ImageUpload from "../ui/ImageUpload";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const SliderForm = (props) => {
  // objects
  const { slider, errorMessage, setErrorMessage, isSubmittingSlider } = props;

  // states
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Slider Title is required"),
    active: Yup.boolean().required("Status is required"),
    order: Yup.number().required("Order is required"),
    image: Yup.mixed().required("Image is Required"),
  });

  // initial values
  const initialValues =
    Object.keys(slider).length !== 0
      ? {
          image: slider.imageFile,
          title: slider.title,
          message: slider.message,
          order: slider.order,
          active: slider.active,
        }
      : {
          image: null,
          title: "",
          order: 0,
          active: true,
          message: "",
        };

  const onFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const isvalid = aspectRatio === 3;
          console.log("aspectRatio", aspectRatio, "is16by9", isvalid);

          if (isvalid) {
            setFieldValue("image", event.target.files[0]);
            setHideDeleteBtn(false);
            setErrorMessage("");
          } else {
            setFieldValue("image", null);
            setErrorMessage(
              "Please select an image with Aspect Ratio 3:1 Or 1440x480 Resolution "
            );
          }
        };
        img.src = e?.target?.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // submit form
  const handleSubmit = async (
    values,
    // @ts-ignore
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {
    setHideDeleteBtn(slider?.imageFile ? false : true);
  }, [slider?.imageFile]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        resetForm,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12} mb={3}>
                  <ImageUpload
                    name="image"
                    landScape={false}
                    // @ts-ignore
                    fullWidth
                    onBlur={handleBlur}
                    hideDeleteBtn={hideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => onFileChange(event, setFieldValue)}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values.image
                        ? URL.createObjectURL(values.image)
                        : "/upload-placeholder.jpg"
                    }
                  />

                  <ImageFieldError errors={errors} touched={touched} />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    name="title"
                    label="Slider Title"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    // @ts-ignore
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <InputLabel id="select-active">Is Active</InputLabel>
                    <Select
                      name="active"
                      labelId="select-active"
                      value={values.active}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                      label="Is Active"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="order"
                    label="Order"
                    value={values.order}
                    error={Boolean(touched.order && errors.order)}
                    // @ts-ignore
                    helperText={touched.order && errors.order}
                    fullWidth
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Slider Message"
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    // @ts-ignore
                    helperText={touched.message && errors.message}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid> */}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => props.navigate("/slider/all")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingSlider}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default SliderForm;
function setFieldValue(arg0, arg1) {
  throw new Error("Function not implemented.");
}
