import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import SendEmailNotificationForm from "../../../components/form/SendEmailNotificationForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export default function EmailNotification() {
  return (
    <>
      <Helmet title="Send Email Notification" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Create Email Notification
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Typography>Create Email Notification</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={3} />
      <Grid container spacing={6} justifyContent={"center"} alignItems="center">
        <Grid item xs={10} md={7}>
          <Card p={8}>
            <SendEmailNotificationForm />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
