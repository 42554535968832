import { Alert } from "@mui/material";
import React from "react";

const AlertMessage = ({ type, title, ...params }) => {
  return (
    <Alert severity={type} sx={{ ...params }}>
      {title}
    </Alert>
  );
};

export default AlertMessage;
