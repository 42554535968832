// @ts-nocheck
import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  blueBorder,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";
import { customColor } from "../../theme/variants";

export default function EmailListTable({
  deleteEmailContact,
  searchBy,
  search,
  filterBy,
  emailLists,
}) {
  const navigate = useNavigate();

  const combinedFilter = (item) => {
    // Check for contact tag filter first
    if (
      filterBy &&
      !item.contactTags?.some((tag) => String(tag.id) === String(filterBy))
    ) {
      return false;
    }

    // Then check for title filter
    switch (searchBy) {
      case "name":
        return item?.name.toLowerCase().includes(search.toLowerCase());
      case "email":
        return item?.email.toLowerCase().includes(search.toLowerCase());
      default:
        return true;
    }
  };

  const filteredEmailLists = emailLists?.filter(combinedFilter);

  return (
    <>
      {filteredEmailLists?.length > 0 ? (
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              ...sxFancyTableBorder,
              td: {
                padding: "8px",
              },
            }}
            aria-label="simple table"
          >
            <TableHead sx={{ ...sxTableHeadBG }}>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone Number</TableCell>
                <TableCell align="left">Tags</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEmailLists?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    "&:hover": { backgroundColor: customColor[25] },
                  }}
                >
                  <TableCell align="left">{row?.name || "N/A"}</TableCell>

                  <TableCell align="left">{row?.email || "N/A"}</TableCell>

                  <TableCell align="left">
                    {row?.phoneNumber || "N/A"}
                  </TableCell>

                  <TableCell>
                    <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                      {row?.contactTags?.map((tag, index) => (
                        <Box
                          key={index}
                          sx={{
                            padding: "4px 8px",
                            ...blueBorder,
                            borderRadius: "8px",
                            textTransform: "capitalize",
                          }}
                        >
                          {tag.tagName}
                        </Box>
                      ))}
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "end",
                      }}
                    >
                      <IconButton
                        color="primary"
                        variant="outlined"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() =>
                          navigate(`/notification/email-lists/edit/${row.id}`)
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => deleteEmailContact(row.id)}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center" my="4rem">
          <Typography variant="h4">
            Apologies, but we couldn't find what you're looking for
          </Typography>
        </Box>
      )}
    </>
  );
}
