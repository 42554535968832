// @ts-nocheck
import { AddCircleOutline } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import {
  deleteContact,
  getAllContactTags,
  getAllContacts,
} from "../../../api/contact";
import EmailListTable from "../../../components/table/EmailListTable";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import NotFound from "../../../components/ui/NotFound";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";
import UploadEmailList from "./UploadEmailList";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export default function EmailLists() {
  const [allContact, setAllContact] = useState([]);
  const [allContactTags, setAllConatctTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchBy, setSearchBy] = useState("name");
  const [search, setSearch] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Name",
      value: "name",
    },
    {
      label: "Email",
      value: "email",
    },
  ];

  // functions
  // fetch Contacts
  const fetchContacts = () => {
    setLoading(true);
    getAllContacts(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
      })
    ).then((res) => {
      setAllContact(res.data.payload.content);
      setCount(res.data.payload.totalElements);
      setRowsPerPage(res.data.payload.pageable.pageSize);
      setCurrentPage(res.data.payload.pageable.pageNumber);
      setLoading(false);
    });
  };

  const fetchContactTags = () => {
    getAllContactTags()
      .then((res) => {
        setAllConatctTags(res.data.payload.content);
      })
      .catch((err) => console.error(err));
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    handleChangePage(0);
  };

  // searching
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      setCurrentPage(0);
    }
  };

  // filtering
  const handleFilter = (e) => {
    const filter = e.target.value;
    console.log(filter);
    // dispatch(filterData(filter));
    setSearchBy(filter);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const handleDeleteEmailContact = async () => {
    setIsDeleting(true);

    try {
      await deleteContact(deleteId);

      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchContacts();
    } catch (error) {
      setIsDeleting(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  // use-effect
  useEffect(() => {
    fetchContacts();
    fetchContactTags();
  }, [currentPage, rowsPerPage]);

  return (
    <>
      <Helmet title="Mailing Contact - Lists" />

      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Email Contact?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={handleDeleteEmailContact}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* buttons */}
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Mailing Contact List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Typography>Mailing Contact List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <UploadEmailList fetchContacts={fetchContacts} />
          <Button
            onClick={() => navigate("/notification/send-email-notification")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutline sx={{ mr: 1 }} />
            Send Email Notification
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {/* filter and search */}
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" gap={3} mb={3}>
                <TextField
                  id="outlined-native"
                  select
                  size="small"
                  label="Search By"
                  value={searchBy}
                  onChange={(e) => handleFilter(e)}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {filters.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  size="small"
                  label="Search Here..."
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              </Stack>
              {/* filter by lists */}
              <Stack direction="row" gap={3} mb={3}>
                <FormControl sx={{ minWidth: "15rem" }} size="small">
                  <InputLabel id="lists">Filter By Contact Tag</InputLabel>
                  <Select
                    labelId="lists"
                    id="lists"
                    value={filterValue || ""}
                    label="Contact Tag"
                    onChange={(e) => setFilterValue(e.target.value)}
                  >
                    {allContactTags.map((item) => (
                      <MenuItem value={item.id}>{item.tagName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            {/* table */}
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allContact.length < 1 ? (
                  <NotFound title={"You don't have any contacts"} />
                ) : (
                  <EmailListTable
                    deleteEmailContact={showDeleteModal}
                    searchBy={searchBy}
                    search={search}
                    filterBy={filterValue}
                    emailLists={allContact}
                  />
                )}
              </>
            )}

            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              count={count}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, page) => {
                handleChangePage(page);
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component="div"
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
