// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateContact } from "../../api/contact";
import ErrorAlert from "../ui/ErrorAlert";

export default function EditContactForm({ user }) {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  // validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    phoneNumber: Yup.number().required("Phone Number is required"),
  });

  // initial values
  const initialValues = {
    name: user?.name,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const data = {
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
      id: user.id,
    };
    try {
      const response = await updateContact(data);

      if (response.data.success) {
        setStatus({ sent: true });
        setSubmitting(false);
        navigate("/notification/email-lists");
      } else {
        setStatus({ sent: false });
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrorMessage(error.message);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Card sx={{ padding: "1rem 1.25rem" }}>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    mb={5}
                    textAlign={"center"}
                    sx={{
                      textDecoration: "underline",
                      textUnderlineOffset: "8px",
                    }}
                  >
                    Edit Contact User
                  </Typography>
                </Grid>

                {/* type title */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="name"
                    label="Name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="email"
                    size="small"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="tel"
                    size="small"
                    name="phoneNumber"
                    label="Phone Number"
                    value={values.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack justifyContent="end" direction="row" gap={4}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/notification/email-lists")}
                      mt={3}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}
