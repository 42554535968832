import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllUser } from "../../api/user";
import params from "../../utils/params";
import ErrorAlert from "../ui/ErrorAlert";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const SendNotificationForm = (props) => {
  const [allUser, setAllUser] = useState([]);
  const { errorMessage } = props;
  const fetchAllUser = () => {
    getAllUser(params({ pageSize: 1000 }))
      .then((res) => {
        setAllUser(res.data.payload.content);
      })
      .catch((error) => console.log("Error: ", error));
  };

  // validation
  const validationSchema = Yup.object().shape({
    users: Yup.array().when("notify", {
      is: "individualUser",
      then: Yup.array().min(1, "Users are required").of(Yup.object()),
      otherwise: Yup.array().notRequired(),
    }),
    message: Yup.string().required("Message is required"),
    title: Yup.string().required("Title is required"),
    notificationType: Yup.string().required("Notification Type is required"),
    notify: Yup.string().required("Please select whom to notify"),
  });

  // initial values
  const initialValues = {
    message: "",
    title: "",
    notificationType: "",
    notify: "",
    users: [],
  };

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    mb={5}
                    textAlign={"center"}
                    sx={{
                      textDecoration: "underline",
                      textUnderlineOffset: "8px",
                    }}
                  >
                    Send Push Notification
                  </Typography>
                </Grid>

                {/* select notification type */}
                <Grid item xs={12}>
                  <FormControl
                    error={Boolean(
                      touched.notificationType && errors.notificationType
                    )}
                    fullWidth
                    size="small"
                  >
                    <InputLabel id="select-active">
                      Notification Type
                    </InputLabel>
                    <Select
                      name="notificationType"
                      labelId="select-notificationType"
                      value={values.notificationType}
                      error={Boolean(
                        touched.notificationType && errors.notificationType
                      )}
                      fullWidth
                      label="Notification Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"SUBSCRIPTION_EXPIRING"}>
                        Subscription Expiring
                      </MenuItem>

                      <MenuItem value={"SUBSCRIPTION_EXPIRED"}>
                        Subscription Expired
                      </MenuItem>

                      <MenuItem value={"EVENT"}>Event</MenuItem>

                      <MenuItem value={"NEWS"}>News</MenuItem>

                      <MenuItem value={"USER_SIGNUP"}>User SignUp</MenuItem>

                      <MenuItem value={"USER_SUBSCRIPTION"}>
                        User Subscription
                      </MenuItem>

                      <MenuItem value={"ORDER"}>Order</MenuItem>

                      <MenuItem value={"OTHER"}>Other</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.notificationType && errors.notificationType}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* type title */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="title"
                    label="Title"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                {/* type message */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="message"
                    label="Message"
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                {/* check all user */}

                <Grid item xs={12}>
                  <FormControl error={Boolean(touched.notify && errors.notify)}>
                    <FormLabel>Notify</FormLabel>
                    <RadioGroup
                      row
                      name="notify"
                      value={values.notify}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="individualUser"
                        control={<Radio />}
                        label="Individual User"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allUser"
                        control={<Radio />}
                        label="All Users"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allSponsors"
                        control={<Radio />}
                        label="All Sponsors"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allBoardMembers"
                        control={<Radio />}
                        label="All Board Members"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {touched.notify && errors.notify}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* select user */}
                {values.notify === "individualUser" && (
                  <Grid item xs={12}>
                    <FormLabel>Notify Individual User</FormLabel>
                    <Autocomplete
                      multiple
                      options={allUser}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option?.name ?? ""}{" "}
                            {option?.name && option?.email ? "-" : ""}(
                            {option?.email ?? ""})
                          </li>
                        );
                      }}
                      getOptionLabel={(option) =>
                        option?.name ?? option?.email ?? ""
                      }
                      onOpen={handleBlur}
                      value={values.users}
                      filterSelectedOptions
                      onChange={(event, value) => setFieldValue("users", value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="users"
                          size="small"
                          error={Boolean(touched.users && errors.users)}
                          helperText={touched.users && errors.users}
                          placeholder="Select Users"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => props.navigate("/notification/all")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Send Notification
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default SendNotificationForm;
