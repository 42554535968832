import axios from "../utils/axios";

// get all notification
export const getAllContacts = (filters) => {
  return axios.get(`api/contacts?${filters}`);
};

export const getAllContactTags = () => {
  return axios.get(`api/contact/tag`);
};

// get demo contacts
export const getDemoContacts = () => {
  return axios.get("api/contacts/download-demo", {
    responseType: "blob",
  });
};

// get contact details
export const getContactDetails = (id) => {
  return axios.get(`api/contacts/details?contactId=${id}`);
};

//update contact
export const updateContact = (data) => {
  return axios.put(`api/contacts`, data);
};

// delete a Contact item
export const deleteContact = (id) => {
  return axios.delete(`api/contacts?contactId=${id}`);
};

//upload contact list
export const uploadContact = ({ file, tags }) => {
  const formattedTags = tags.join(",");

  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`api/contacts/import?listIds=${formattedTags}`, formData);
};

// create contact tag
export const createContactTag = (data) => {
  return axios.post("api/contact/tag", { tagName: data });
};

export const sendEmail = (data) => {
  return axios.post("api/contacts/send-mail", data);
};
