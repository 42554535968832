import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

export default function Editor({
  value,
  setValue,
  placeholder,
  error,
  helperText,
}) {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <FormControl error={error} fullWidth size="small">
      <ReactQuill
        labelId="description-label"
        theme="snow"
        bounds=".app"
        value={value}
        modules={modules}
        formats={formats}
        onChange={setValue}
        placeholder={placeholder || "Write you email here..."}
        style={{
          width: "100%",
          height: "250px",
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
