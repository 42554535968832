// @ts-nocheck
import { AddCircleOutline, CloudUpload } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Button,
  Checkbox,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  createContactTag,
  getAllContactTags,
  getDemoContacts,
  uploadContact,
} from "../../../api/contact";
import ContentLoader from "../../../components/ui/ContentLoader";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import { customColor } from "../../../theme/variants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 50vw)",
  maxHeight: "calc(100% - 20vh)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 8,
  borderRadius: "0.5rem",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customColor[100],
    borderRadius: "24px",
  },
};

export default function UploadEmailList({ fetchContacts }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [contactTagLoading, setContactTagLoading] = useState(false);
  const [addTagLoading, setAddTagLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [listFieldOpen, setListFieldOpen] = useState(false);

  const [contactTags, setAllContactTags] = useState(null);
  const [file, setFile] = useState(null);
  const [taglists, setTagLists] = useState([]);
  const [tagName, setTagName] = useState(null);

  const downloadSample = async () => {
    setIsDownloading(true);
    try {
      const response = await getDemoContacts();
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.setAttribute("download", "sample-spreadsheet.xlsx");
      downloadLink.click();

      // Clean up the blob URL and reset loading state
      window.URL.revokeObjectURL(fileUrl);

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  // fetch contact Tags
  const fetchContactsTags = () => {
    setContactTagLoading(true);
    getAllContactTags().then((res) => {
      setAllContactTags(res.data.payload.content);
      setContactTagLoading(false);
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateTag = async () => {
    try {
      await createContactTag(tagName);
      setAddTagLoading(false);
      setListFieldOpen(false);
      setTagName(null);
      fetchContactsTags();
    } catch (error) {
      setAddTagLoading(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    const arrayOfTagIds = taglists.map((tag) => tag.id);

    try {
      await uploadContact({
        file,
        tags: arrayOfTagIds,
      });
      setLoading(false);
      setOpen(false);
      setFile(null);
      setTagLists([]);
      fetchContacts();
    } catch (error) {
      setLoading(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  useEffect(() => {
    fetchContactsTags();
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ ml: 2 }}
        onClick={handleOpen}
      >
        <CloudUpload sx={{ mr: 1 }} />
        Upload Mailing List
      </Button>
      <Modal open={open} onClose={handleClose}>
        {loading ? (
          <ContentLoader />
        ) : (
          <Box sx={style}>
            {/* modal title */}
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h3"
              textAlign="center"
            >
              Upload Mailing List
            </Typography>
            {/* modal body */}
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              {/* download button */}
              <Box>
                {isDownloading ? (
                  <Typography variant="body2">Downloading...</Typography>
                ) : (
                  <Typography
                    variant={"caption"}
                    component="a"
                    fontSize="0.875rem"
                    fontWeight="medium"
                    sx={{
                      color: customColor[700],
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                    onClick={downloadSample}
                  >
                    Download Sample Spreadsheet
                  </Typography>
                )}
              </Box>

              {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}

              {/* upload file */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                border="1px solid #d9dede"
                borderRadius="0.5rem"
                py={10}
                my={4}
              >
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  {file?.name ? "Uploaded" : "Upload File"}
                  <VisuallyHiddenInput
                    type="file"
                    accept=".xlsx, .csv"
                    onChange={handleChange}
                  />
                </Button>
                <Typography variant="p" component="em" fontSize="1rem" mt={3}>
                  {file?.name || "No file selected"}
                </Typography>
              </Box>

              {/* add audience */}
              {/* lists */}
              <Stack direction="row" spacing={2} alignItems="center" mb={4}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={contactTags}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.tagName}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.tagName}
                    </li>
                  )}
                  value={taglists}
                  onChange={(e, value) => setTagLists(value)}
                  style={{ width: 500 }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Give Contact A Tag"
                      placeholder="Tag"
                    />
                  )}
                />
                {!listFieldOpen ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setListFieldOpen(true)}
                  >
                    <AddCircleOutline sx={{ mr: 1 }} />
                    New Tag
                  </Button>
                ) : null}
              </Stack>
            </Box>
            {/* list create */}
            {listFieldOpen ? (
              <Stack direction="row" spacing={2} mb={4}>
                <TextField
                  label="Tag Name"
                  value={tagName}
                  id="fullWidth"
                  size="small"
                  style={{ width: 345 }}
                  onChange={(e) => setTagName(e.target.value)}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setListFieldOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={addTagLoading}
                  onClick={handleCreateTag}
                >
                  Create
                </Button>
              </Stack>
            ) : null}
            {/* buttons */}
            <Stack direction="row" justifyContent={"end"} spacing={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Close
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => handleUpload()}
              >
                Upload List
              </Button>
            </Stack>
          </Box>
        )}
      </Modal>
    </>
  );
}
