import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";

import { Box, Paper, Typography } from "@mui/material";

import ForgetPasswordComponent from "../../components/auth/ForgetPassword";
import { imageDefaultStyleContain } from "../../theme/globalStyles";
import { ReactComponent as Logo } from "../../vendor/logo.svg";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ForgetPassword() {
  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Wrapper>
        <Helmet title="Reset Password" />

        <Box sx={{ height: "160px", width: "160px", margin: "0 auto", mb: 3 }}>
          <img
            src="/static/img/asian-logo.png"
            alt="asian-logo"
            // @ts-ignore
            style={{ ...imageDefaultStyleContain }}
          />
        </Box>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ForgetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ForgetPassword;
