import axios from "../utils/axios";

export const getUser = () => axios.get(`api/auth/me`);

// get all user
export const getAllUser = (filters) => {
  return axios.get(`api/users/all?${filters}`);
};

// verify user
export const verifyUser = (data) => {
  return axios.post("api/users/verify", data);
};

// get a specific user
export const getUserById = (id) => {
  return axios.get(`api/users/${id}`);
};

// get a specific user's Dependents
export const getUserDependents = (id) => {
  return axios.get(`api/user/dependent/userId/${id}/all`);
};

export const updateDependentInformation = (data) => {
  return axios.put("api/user/dependent/admin/update", data);
};

// delete dependent of a specific user
export const deleteDependent = (id) => {
    return axios.delete(`api/user/dependent/id/${id}/delete`);
 }

// block user
export const getBlockUnBlockUser = (id) => {
  return axios.get(`api/users/block-unblock/userId/${id}`);
};

// delete user
export const deleteUser = (id) => {
  return axios.delete(`api/users/delete/userId/${id}`);
};

// import user and subscription from excel
export const importUserAndSubscription = (data) => {
  return axios.post("api/users/create-excel", data);
};

// user profile picture update
export const updateUserProfilePicture = (data) => {
  return axios.put("api/auth/profile-picture/update", data);
};

// user profile information update
export const updateUserProfileInformation = (data) => {
  return axios.put("api/auth/profile/update", data);
};

// download user info - excel format
export const downloadUserInfoExcel = () =>
  axios.get("api/users/user-information/download");
