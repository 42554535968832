// @ts-nocheck
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";

import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { getAllContactTags, sendEmail } from "../../api/contact";
import { getAllUser } from "../../api/user";
import params from "../../utils/params";
import AlertMessage from "../ui/AlertMessage";
import Editor from "../ui/Editor";
import ErrorAlert from "../ui/ErrorAlert";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SendEmailNotificationForm({ errorMessage }) {
  const [allUser, setAllUser] = useState([]);
  const [emailBody, setEmailBody] = useState("");
  const [allContactTags, setAllContactTags] = useState([]);
  const [message, setMessage] = useState();

  const navigate = useNavigate();

  // fetch contact Tags
  const fetchContactsTags = () => {
    getAllContactTags()
      .then((res) => {
        setAllContactTags(res.data.payload.content);
      })
      .catch((error) => console.log("Error: ", error));
  };

  // functions
  const fetchAllUser = () => {
    getAllUser(params({ pageSize: 1000 }))
      .then((res) => {
        setAllUser(res.data.payload.content);
      })
      .catch((error) => console.log("Error: ", error));
  };

  // validation
  const validationSchema = Yup.object().shape({
    users: Yup.array().when("emailTo", {
      is: "individualUser",
      then: Yup.array().min(1, "Users are required").of(Yup.object()),
      otherwise: Yup.array().notRequired(),
    }),
    subject: Yup.string().required("Subject is required"),
    notificationType: Yup.string().required("Notification Type is required"),
    emailTo: Yup.string().required("Please select whom to email"),
  });

  // initial values
  const initialValues = {
    subject: "",
    notificationType: "",
    emailTo: "",
    users: [],
    tags: [],
  };

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    let data = {
      emailType: values.notificationType,
      subject: values.subject,
      body: emailBody,
      isAllBoardMembers: false,
      isAllSponsors: false,
      isAllUsers: false,
      isIndividualUsers: false,
      isNonRegisteredUsers: false,
    };

    console.log(values.tags);

    switch (values.emailTo) {
      case "allUser":
        data.isAllUsers = true;
        break;
      case "allSponsors":
        data.isAllSponsors = true;
        break;
      case "allBoardMembers":
        data.isAllBoardMembers = true;
        break;
      case "individualUser":
        data.isIndividualUsers = true;
        data.recipients = values.users.map((item) => item.email);
        break;
      case "contactTag":
        data.isNonRegisteredUsers = true;
        data.contactTagIds = values.tags.map((item) => item.id);
        break;
      default:
      // Handle default case if necessary
    }

    setSubmitting(true);

    try {
      const response = await sendEmail(data);

      if (response.data.success) {
        setStatus({ sent: true });
        resetForm();
        setEmailBody("");
        setMessage(response.data.message);
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: response.data.message });
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchAllUser();
    fetchContactsTags();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    mb={5}
                    textAlign={"center"}
                    sx={{
                      textDecoration: "underline",
                      textUnderlineOffset: "8px",
                    }}
                  >
                    Send Email Notification
                  </Typography>
                </Grid>

                {/* select notification type */}
                <Grid item xs={12}>
                  <FormControl
                    error={Boolean(
                      touched.notificationType && errors.notificationType
                    )}
                    fullWidth
                    size="small"
                  >
                    <InputLabel id="select-active">
                      Notification Type
                    </InputLabel>
                    <Select
                      name="notificationType"
                      labelId="select-notificationType"
                      value={values.notificationType}
                      error={Boolean(
                        touched.notificationType && errors.notificationType
                      )}
                      fullWidth
                      label="Notification Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"SUBSCRIPTION_EXPIRING"}>
                        Subscription Expiring
                      </MenuItem>

                      <MenuItem value={"SUBSCRIPTION_EXPIRED"}>
                        Subscription Expired
                      </MenuItem>

                      <MenuItem value={"EVENT"}>Event</MenuItem>

                      <MenuItem value={"NEWS"}>News</MenuItem>

                      <MenuItem value={"USER_SIGNUP"}>User SignUp</MenuItem>

                      <MenuItem value={"USER_SUBSCRIPTION"}>
                        User Subscription
                      </MenuItem>

                      <MenuItem value={"ORDER"}>Order</MenuItem>

                      <MenuItem value={"OTHER"}>Other</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.notificationType && errors.notificationType}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* type title */}
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="subject"
                    label="Subject"
                    value={values.subject}
                    error={Boolean(touched.subject && errors.subject)}
                    helperText={touched.subject && errors.subject}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Editor value={emailBody} setValue={setEmailBody} />
                </Grid>

                {/* check all user */}

                <Grid item xs={12} mt={18}>
                  <FormControl
                    error={Boolean(touched.emailTo && errors.emailTo)}
                  >
                    <FormLabel>Email To </FormLabel>
                    <RadioGroup
                      row
                      name="emailTo"
                      value={values.emailTo}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="individualUser"
                        control={<Radio />}
                        label="Individual User"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allUser"
                        control={<Radio />}
                        label="All Users"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allSponsors"
                        control={<Radio />}
                        label="All Sponsors"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="allBoardMembers"
                        control={<Radio />}
                        label="All Board Members"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="contactTag"
                        control={<Radio />}
                        label="Contact List Users"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {touched.emailTo && errors.emailTo}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* select user */}
                {values.emailTo === "individualUser" && (
                  <Grid item xs={12}>
                    <FormLabel>Notify Individual User</FormLabel>
                    <Autocomplete
                      multiple
                      options={allUser}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option?.name ?? ""}{" "}
                            {option?.name && option?.email ? "-" : ""}(
                            {option?.email ?? ""})
                          </li>
                        );
                      }}
                      getOptionLabel={(option) =>
                        option?.name ?? option?.email ?? ""
                      }
                      onOpen={handleBlur}
                      value={values.users}
                      filterSelectedOptions
                      onChange={(event, value) => setFieldValue("users", value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="users"
                          size="small"
                          error={Boolean(touched.users && errors.users)}
                          helperText={touched.users && errors.users}
                          placeholder="Select Users"
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* select tags */}
                {values.emailTo === "contactTag" ? (
                  <Grid item xs={12} gap={2}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={allContactTags}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.tagName}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.tagName}
                        </li>
                      )}
                      onOpen={handleBlur}
                      value={values.tags}
                      onChange={(event, value) => setFieldValue("tags", value)}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact Tag"
                          placeholder="Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>

              {message && <AlertMessage type="success" title={message} />}

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/notification/email-lists")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmitting}
                  >
                    Send Notification
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
}
